<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-container class="bv-example-row" fluid>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-brand href="/">Easy Envelopes</b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="loggedin" href="/Envelopes">Envelopes</b-nav-item>
            <b-nav-item v-if="loggedin" href="/Accounts">Accounts</b-nav-item>
            <b-nav-item v-if="loggedin" href="/Transactions">Transactions</b-nav-item>
            <b-nav-item v-if="loggedin" href="/Budget">Budget</b-nav-item>
            <b-nav-item v-if="loggedin" href="/Income">Income</b-nav-item>
            <b-nav-item v-if="loggedin" href="/Settings">Settings</b-nav-item>
            <b-nav-item v-if="!loggedin" @click="login">Login</b-nav-item>
            <b-nav-item v-if="loggedin" @click="logout">Logout</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  created() {
    //console.log(this.$auth);
    // console.log(this.userIsAuthorized)
  },
  data() {
    return { devchange: false };
  },
  components: {},
  computed: {
    ...mapGetters(['userIsAuthorized', 'getUserStatus']),
    loggedin() {
      if (this.userIsAuthorized || this.devchange) {
        // return this.$auth.isAuthenticated;
        return true;
      }
    },
  },
  methods: {
    login() {
      this.$store.dispatch('login')
    },
    // Log the user out
    logout() {
      this.$store.dispatch('logout')
    },
  },
  name: "Header",
};
</script>

<style scoped>
.errorRed>* {
  color: red !important;
}
</style>