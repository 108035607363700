<template>
  <div>

    <b-row>

      <b-col cols="6">
        <b-form-group>
          <b-form-file accept="image/*" @change="onFileChange" :multiple="false"></b-form-file>
          <b-overlay :show="loading" rounded="sm">
            <label for="merchant">Merchant:</label>
            <b-input id="merchant" v-model="receiptInfo.merchant" placeholder="Merchant"></b-input>

            <label for="date">Start Date:</label>
            <b-form-datepicker id="date" :value-as-date="true" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" type="date" v-model="receiptInfo.date" />

            <label for="total">Total:</label>
            <b-input id="total" v-model="receiptInfo.total" placeholder="Total"></b-input>
          </b-overlay>
        </b-form-group>
        <b-button @click="SaveReceiptInfo">Save Receipt</b-button>
      </b-col>
      <b-col cols="6">
        <!-- // Display the receipt image -->
        <img class="receipt-image" v-if="receipt" :src="createObjectURL(receipt)" alt="Receipt" />

      </b-col>
    </b-row>





    <b-modal title="Help make this better?" id="confirm-modal" ok-title="Yes" cancel-title="No"
      @ok="TrainableConfirmYes" @cancel="TrainableConfirmNo">
      <p>Can we use your receipts for training our OCR model to make the scanning accuracy better?</p>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ReceiptUpload', // Add the name property
  created() {
    // retrieve the setting from the api
    axios.get("/api/settings").then((r) => {
      this.settings = r.data;
      if (this.settings.receiptsTrainable === null) {
        this.$bvModal.show('confirm-modal');
      }
    });
  },
  data() {
    return {
      loading: false,
      settings: {},
      receipt: null,
      receiptInfo: {
        merchant: '',
        date: '',
        total: 0,
        rawtext: "",
        upc_list: []
      }
    };
  },
  methods: {
    createObjectURL(file) {
      return URL.createObjectURL(file);
    },
    TrainableConfirmYes() {
      this.$bvModal.hide('confirm-modal');
      this.updateReceiptsTrainable(true);
    },
    TrainableConfirmNo() {
      this.$bvModal.hide('confirm-modal');
      this.updateReceiptsTrainable(false);
    },
    updateReceiptsTrainable(value) {

      this.settings.receiptsTrainable = value;
      axios.post("/api/settings", this.settings);
    },
    SaveReceiptInfo() {
      // Create a FormData object to hold the receipt image
      const formData = new FormData();
      formData.append('file', this.receipt);
      formData.append('receiptInfo', JSON.stringify(this.receiptInfo)); // Assuming receiptInfo is an object

      // Save the receipt info to the database
      axios.post('/api/receipt', formData)
        .then(response => {
          // Handle the response from the backend API
          console.log(response);
        })
        .catch(error => {
          // Handle any errors that occur during the API request
          console.error(error);
        });
    },
    onFileChange(e) {
      this.receipt = e.target.files[0];
      this.ParseReceipt()
    },
    async ParseReceipt() {

      if (!this.receipt) return;
      this.loading = true;
      // Create a FormData object to hold the receipt image
      const formData = new FormData();
      formData.append('file', this.receipt);

      // Submit the receipt image via axios
      axios.post('/api/receiptParse', formData)
        .then(response => {
          // Handle the response from the backend API
          // response.data.data.date = new Date(response.data.data?.parsed.date);
          this.receiptInfo.date = new Date(response.data.data?.parsed.date);
          this.receiptInfo.merchant = response.data.data?.parsed.merchant;
          this.receiptInfo.total = response.data.data?.parsed.total;
          this.receiptInfo.rawtext = response.data.data?.rawtext;
          this.receiptInfo.upc_list = response.data.data?.parsed.upc_list;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          // Handle any errors that occur during the API request
          console.error(error);

        });
    },
  },
};
</script>

<style scoped>
.receipt-image {
  max-width: 100%;
  max-height: 500px;
}
</style>