<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="d-flex justify-content-center align-items-center">
      <!-- <b-spinner v-if="loading"></b-spinner> -->
    </div>
    <div class="item my-5">
      <div class="position-relative"> <!-- Add this wrapper -->
        <!-- Your other UI elements here -->

        <!-- <b-overlay :show="loading" spinner-variant="primary" opacity="0.6" class="overlay">
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner v-if="loading"></b-spinner>
          </div>
        </b-overlay> -->
      </div>
      <b-col cols="12" md="12">
        <!-- <div v-if="$auth.user.name"> -->
        <div>
          <h2>Settings</h2>
        </div>
      </b-col>
      <b-tabs pills card vertical>
        <b-tab title="Envelopes" active>
          <h1>Envelopes</h1>
          <b-card>
            <b-button size="sm" @click="RefreshMonthlyTotals()" class="mr-1">Refresh Monthly Totals</b-button>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Show Budget amount in Envelopes" label-for="input-defaultenvelope">
              <b-form-checkbox :disabled="loading" v-model="Allsettings.envelopeshowbudget" id="input-defaultenvelope"
                switch @change="SaveSettings()">
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Default Envelope" label-for="input-defaultenvelope">
              <model-select :disabled="loading" @input="SaveSettings()" id="input-Sourceenvelope"
                :options="EnvelopeOptions" v-model="Allsettings.defaultenvelope">
              </model-select>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7" label="Invalid Splits"
              label-for="invalidSplits">
              <div v-if="invalidSplits.length > 0" class="error">
                <p id="invalidSplits">{{ invalidSplits.length }}</p>
                <b-button v-b-toggle.collapse-invalidSplits size="sm">Toggle Inner Collapse</b-button>
              </div>
              <div v-else>
                <p id="invalidSplits">{{ invalidSplits.length }}</p>
              </div>
            </b-form-group>
            <b-collapse id="collapse-invalidSplits" class="mt-2">
              <b-table :items="invalidSplits"></b-table>
            </b-collapse>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Invalid Transactions" label-for="invalidTransactions">
              <div v-if="invalidTransactions.length > 0" class="error">
                <p id="invalidTransactions">{{ invalidTransactions.length }}</p>
                <b-button v-b-toggle.collapse-invalidTransactions size="sm">Toggle Inner Collapse</b-button>
              </div>
              <div v-else>
                <p id="invalidTransactions">{{ invalidTransactions.length }}</p>
              </div>
            </b-form-group>
            <b-collapse id="collapse-invalidTransactions" class="mt-2">
              <b-table :items="invalidTransactions"></b-table>
            </b-collapse>

            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Invalid Transfers" label-for="invalidtransfers">
              <div v-if="invalidtransfers.length > 0" class="error">
                <p id="invalidtransfers">{{ invalidtransfers.length }}</p>
                <b-button v-b-toggle.collapse-invalidtransfers size="sm">Toggle Inner Collapse</b-button>
              </div>
              <div v-else>
                <p id="invalidtransfers">{{ invalidtransfers.length }}</p>
              </div>
            </b-form-group>
            <b-collapse id="collapse-invalidtransfers" class="mt-2">
              <b-table :items="invalidtransfers"></b-table>
            </b-collapse>
          </b-card>
        </b-tab>
        <b-tab title="Budgeting">
          <h1>Budgeting</h1>
          <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7" label="Budgeting Cycle:"
            label-for="BudgetingCycle">
            <b-form-select id="BudgetingCycle" v-model="Allsettings.budgetingCycle" @change="SaveSettings()"
              :options="['Weekly', 'Bi-Weekly', 'Monthly', 'Yearly']"></b-form-select>
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7" label="# of Profiles to show:"
            label-for="ProfileCount">
            <div style="display: flex; align-items: center;">
              <h5>{{ Allsettings.profileCount }}</h5>
              <b-form-input id="ProfileCount" type="range" v-model="Allsettings.profileCount" @change="SaveSettings()" min="1" max="5"></b-form-input>
            </div>
          </b-form-group>
        </b-tab>
        <b-tab title="Connected Accounts">
          <h1>Connected Accounts</h1>
          <b-card>
            <b-card-title>Currently Connected:
              <b-button @click="GetAccounts">Refresh</b-button>
            </b-card-title>
            <b-card-text>
              <b-row>
                <b-col v-if="PlaidAccounts" v-for="institution in PlaidAccounts" :key="institution.account_id">
                  <b-card>
                    <b-card-title>{{ institution.institution_name }}
                      <b-button size="sm" variant="danger" @click="deletePlaidInstitution(institution)" class="mr-1">
                        <b-icon icon="trash"></b-icon> Delete
                      </b-button>
                    </b-card-title>
                    <b-card-text>
                      <b-row v-for="account in institution.accounts" :key="account.id">
                        {{ account.name }}
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
          <b-card>
            <h4>Add new</h4>
            <Plaid @Refresh="Refresh" />
          </b-card>
        </b-tab>
        <b-tab title="Account">
          <h1>Account</h1>
          <b-card>

            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Account created on:" label-for="input-usereceipts">
              {{formattedFirstLoginDate}}
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Trial started on:" label-for="input-usereceipts">
              {{formattedTrialStartDate}}
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Allow Easy Envelopes to use your receipts in our training database?" label-for="input-receiptsTrainable">
              <b-form-checkbox :disabled="loading" v-model="Allsettings.receiptsTrainable" id="input-receiptsTrainable"
                switch @change="SaveSettings()"></b-form-checkbox>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Use custom storage for reciept storage?" label-for="input-fundingProfileUseIncomeTotals">
              <b-form-checkbox :disabled="loading" v-model="Allsettings.receiptsCustomStorage" id="input-fundingProfileUseIncomeTotals"
                switch @change="SaveSettings()"></b-form-checkbox>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="5" content-cols-sm content-cols-lg="7"
              label="Use Income total for Funding Profiles?" label-for="input-fundingProfileUseIncomeTotals">
              <b-form-checkbox :disabled="loading" v-model="Allsettings.fundingProfileUseIncomeTotals" id="input-fundingProfileUseIncomeTotals"
                switch @change="SaveSettings()"></b-form-checkbox>
            </b-form-group>
            <b-button size="sm" variant="danger" @click="resetAccount" class="mr-1">Reset Account</b-button>
            <b-button size="sm" variant="danger" @click="deleteAccount" class="mr-1">Delete Account</b-button>
          </b-card>
        </b-tab>
        <b-tab title="Import/Migration">
          <h1>Import/Migration</h1>
          <b-card>
            <b-select v-model="ImportFrom" :options="CompanyOptions">
            </b-select>
            <b-form-file ref="importcsv" v-model="ImportFile" placeholder="ImportCSV" drop-placeholder="ImportCSV"
              @change="uploadCSV" accept=".csv" />
          </b-card>
        </b-tab>
      </b-tabs>
      <b-modal id="ImportCSVFieldMapping" title="Field Mapping" @ok="SubmitImportMint">
        <b-card>
          <h3>Select Envelopes</h3>
          <div v-bind:key="i.property" v-for="i in envelopeMappingSelection">
            <b-col cols="12">
              <b-form-group label-cols-sm="5" label-cols-lg="4" content-cols-sm="7" content-cols-lg="8" :label="i.csv">
                <model-select :options="CSVImportEnvelopeOptions(i.csv)" v-model="i.CurrentEnvelopeId"></model-select>
              </b-form-group>
            </b-col>
          </div>
        </b-card>

        <b-card>
          <h3>Select Accounts</h3>
          <div v-bind:key="i.property" v-for="i in accountMappingSelection">

            <b-col cols="12">
              <b-form-group label-cols-sm="5" label-cols-lg="4" content-cols-sm="7" content-cols-lg="8" :label="i.csv">
                <model-select :options="CSVImportAccountOptions(i.csv)" v-model="i.CurrentAccountId"></model-select>

                <div v-if="i.CurrentAccountId === null">
                  <b-form-group label-cols-sm="5" label-cols-lg="4" content-cols-sm="7" content-cols-lg="8"
                    label="Mint Current Balance:">
                    <money v-bind:class="i.currentbalance < 0 ? 'error' : 'success'" v-model="i.currentbalance"
                      class="form-control"></money>
                  </b-form-group>
                </div>
              </b-form-group>
            </b-col>
          </div>
        </b-card>
      </b-modal>
    </div>
  </b-overlay>
</template>
<script>

import axios from "axios";
import Plaid from "../components/Plaid.vue";
import { ModelSelect } from "vue-search-select";
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: "Settings",
  data() {
    return {
      profileCount: "1",
      CompanyOptions: [{
        value: null,
        text: "-- Select Import From --",
      },
      {
        value: 1,
        text: "Import from YNAB",
      },
      {
        value: 2,
        text: "Import from Mint",
      },
      {
        value: 3,
        text: "Import from CSV",
      }],
      importresults: [],
      ImportFrom: null,
      ImportFile: [],
      invalidtransfers: [],
      invalidSplits: [],
      invalidTransactions: [],
      Allsettings: { receiptsCustomStorage: false, fundingProfileUseIncomeTotals: false, envelopeshowbudget: false, defaultenvelope: null, receiptsTrainable: false, budgetingCycle: "Bi-Monthly", profileCount: 1},
      envelopeMappingSelection: [],
      accountMappingSelection: [],
      Envelopes: [],
      Accounts: [],
      PlaidAccounts: [],
      loading: false,
    };
  },
  watch: {
    profileCount(newVal) {
      if (Number(newVal) > 5) {
        this.profileCount = "5";
      } else if (Number(newVal) < 1) {
        this.profileCount = "1";
      }
    },
  },
  components: { Plaid, ModelSelect },
  computed: {
    ...mapGetters(['getUserStatus']),
    formattedFirstLoginDate() {
      return moment(this.getUserStatus.firstLoginDate).format('MMMM Do YYYY');
    },
    formattedTrialStartDate() {
      return moment(this.getUserStatus.trialStartDate).format('MMMM Do YYYY');
    },
    EnvelopeOptions() {
      if (this.Envelopes) {
        var data = this.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        data.unshift({ value: null, text: "-- Select Envelope --" });
        return data;
      } else {
        return { value: null, text: "None" };
      }
    },

  },
  methods: {
    SubmitImportMint() {
      console.log("SubmitImportMint");
      console.log(this.envelopeMappingSelection);
      console.log(this.accountMappingSelection);
      console.log(this.importresults);
      axios.post("/api/Account/Import/Mint", {
        envelopeMapping: this.envelopeMappingSelection,
        accountMapping: this.accountMappingSelection,
        transactions: this.importresults
      }).then((r) => {
        console.log(r);
      });
    },
    CSVImportEnvelopeOptions(defaultname) {
      if (this.Envelopes) {
        var data = this.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        data.unshift({ value: null, text: `Mint Import | ${defaultname}` });
        return data;
      } else {
        return { value: null, text: "None" };
      }
    },
    CSVImportAccountOptions(defaultname) {
      if (this.Accounts) {
        var data = this.Accounts.map((i) => ({
          value: i.id,
          text: `${i.name}`,
        }));
        data.unshift({ value: null, text: `${defaultname}` });
        return data;
      } else {
        return [{ value: null, text: "None" }];
      }
    },
    uploadCSV(e) {
      var files = e.target.files || e.dataTransfer.files;
      //console.log(files);
      let filename = files[0].name;
      if (!files.length) return;
      const file = files[0];
      const reader = new FileReader();
      var results;
      reader.onload = () => {
        try {
          results = this.csvToJson(reader.result);
          this.importresults = results;
          this.importenvelopemappingMint(); // Call the function here
          this.$bvModal.show('ImportCSVFieldMapping')
          // this.$refs['ImportCSVFieldMapping'].show()
        } catch (error) {
          console.log(error);
          alert(error);
          this.fileerror = error;
        }
      };
      reader.readAsText(file);

      // setTimeout(
      //   function () {
      //     this.importenvelopemappingMint();
      //     // this.$refs["importcsv"].reset();
      //     // this.file = null;
      //   }.bind(this),
      //   500
      // );

    },
    importenvelopemappingMint() {
      // console.log("importenvelopemappingMint");
      // console.log(this.importresults);
      //get all unique categories from the req.body.csv
      const categories = this.importresults.map((i) => {
        return i.Category
      })
      // console.log(categories)
      //remove duplicates
      var uniquecategories = categories.filter((value, index, array) => array.indexOf(value) === index);


      //get all unique accounts from the req.body.csv
      const accounts = this.importresults.map((i) => {
        return i["Account Name"]
      })
      // console.log(accounts)
      //remove duplicates
      var uniqueaccounts = accounts.filter((value, index, array) => array.indexOf(value) === index);

      console.log(uniquecategories)
      console.log(uniqueaccounts)

      this.envelopeMappingSelection = uniquecategories.map((i) => {
        return {
          csv: i,
          CurrentEnvelopeId: null
        }
      })

      this.accountMappingSelection = uniqueaccounts.map((i) => {
        return {
          csv: i,
          CurrentAccountId: null
        }
      })

    },
    async resetAccount() {
      const confirmation = confirm("Are you sure you want to reset your account and delete all information related to your account?");
      if (confirmation) {
        this.loading = true;
        await axios.post("/api/user/reset");
        await this.Refresh();
        this.loading = false;

      }
    },
    deleteAccount() {
      const confirmation = confirm("Are you sure you want to delete your account and all the information in your account?");
      if (confirmation) {
        console.log("Delete account");
        // axios.post("/api/RefreshMonthlyTotals");
      }
    },
    deletePlaidInstitution(institution) {
      console.log(institution)
      const confirmation = confirm(`Are you sure you want to delete your connection to ${institution.institution_name}?`);
      if (confirmation) {
        console.log("Delete account");
        axios.delete("/api/plaid/account", { data: institution }).then((r) => {
          console.log(r);
          this.GetAccounts();
        });
      }
    },
    RefreshMonthlyTotals() {
      axios.post("/api/RefreshMonthlyTotals");
    },
    // onLoad() {
    //   console.log("onLoad");
    // },
    GetAccounts() {
      axios.get("/api/plaid/Accounts").then((res) => (this.PlaidAccounts = res.data));
    },
    SaveSettings() {
      console.log("SavingSettings");
      console.log(this.Allsettings);
      axios.post("/api/settings", this.Allsettings).then((r) => {
        //        console.log(r);
      });
    },
    async Refresh() {
      axios.get("/api/transfers/invalid").then((r) => {
        if (r.data.length > 0) {
          this.invalidtransfers = r.data;
          console.log(r.data);
        }
      });
      axios.get("/api/transactionsplits/invalid").then((r) => {
        if (r.data.length > 0) {
          this.invalidSplits = r.data;
          console.log(r.data);
        }
      });
      axios.get("/api/transactions/invalid").then((r) => {
        if (r.data.length > 0) {
          this.invalidTransactions = r.data;
          console.log(r.data);
        }
      });
      axios.get("/api/settings").then((r) => {
        this.loading = true;
        // console.log(r);
        if (r.data) {
          this.Allsettings = Object.assign(this.Allsettings, r.data);
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
      axios.get("/api/Envelopes").then((res) => (this.Envelopes = res.data));
      axios.get("/api/Accounts").then((res) => (this.Accounts = res.data));
      this.GetAccounts();
    }
    // onExit(err, metadata) {
    //   console.log("onExit", err, metadata);
    // },
    // onEvent(eventName, metadata) {
    //   console.log("onEvent", eventName, metadata);
    // },
  },
  mounted() {
    this.Refresh();
  },
};
</script>
