<template>
  <div @keydown.enter="getRecurringDates">


    <b-modal title="New Income" @show="onShow" @hidden="resetNewModal" id="NewIncome" hide-footer>
      <!-- <h1>Recurring Transaction</h1> -->
      <b-row>
        <b-col cols="6">

          <b-form-group>
            <label for="name">Name:</label>
            <b-form-input id="name" v-model="NewIncome.name"></b-form-input>
            <label for="amount">Amount:</label>
            <money id="amount" v-bind:class="NewIncome.amount < 0 ? 'error' : 'success'" class="form-control"
              v-model="NewIncome.amount">
            </money>
            <label for="description">Description:</label>
            <b-form-input id="description" v-model="NewIncome.description"></b-form-input>
            <label for="target-envelope">Account:</label>
            <model-select id="target-envelope" :options="AccountOptions" v-model="NewIncome.accountId"></model-select>

            <label for="start-date">Start Date:</label>
            <b-form-datepicker id="start-date" v-model="NewIncome.startDate"
              @input="updateSelectedDay"></b-form-datepicker>
            <label for="show-end-date">Show End Date:</label>
            <b-form-checkbox id="show-end-date" v-model="NewIncome.showEndDate"></b-form-checkbox>
            <div v-if="NewIncome.showEndDate">
              <label for="end-date">End Date:</label>
              <b-form-datepicker id="end-date" v-model="NewIncome.endDate"></b-form-datepicker>
              <!-- Add end date picker -->
            </div>

            <label for="Template">Template:</label>
            <b-form-select id="Template" v-model="NewIncome.selectedFrequencyTemaplate"
              :options="frequencyTemplate"></b-form-select>

            <div v-if="NewIncome.selectedFrequencyTemaplate === 3">

              <label for="RecurringFrequency">Frequency:</label>
              <b-form-select id="RecurringFrequency" v-model="NewIncome.RecurringFrequency"
                :options="frequencyOptions"></b-form-select>

              <div v-if="NewIncome.RecurringFrequency === 0">
                <label for="selected-day">Select Interval:</label>
                <b-form-input v-model="NewIncome.RecurringInterval" type="number" min="1"
                  placeholder="Interval"></b-form-input>
              </div>

              <div v-if="NewIncome.RecurringFrequency === 1">
                <label for="selected-day">Select Day:</label>
                <b-form-select id="selected-day" v-model="NewIncome.selectedDay" :options="dayOptions"></b-form-select>
                <label for="selected-day">Select Interval:</label>
                <b-form-input v-model="NewIncome.RecurringInterval" type="number" min="1"
                  placeholder="Interval"></b-form-input>
              </div>


              <div v-if="NewIncome.RecurringFrequency === 2">
                <label for="selected-day">Select Day:</label>
                <b-form-select id="selected-day" v-model="NewIncome.selectedDay" :options="dayOptions"></b-form-select>
                <label for="selected-occurrence">Select Occurrence:</label>
                <b-form-select id="selected-occurrence" v-model="NewIncome.selectedOccurrence"
                  :options="Dayofweekoccurence"></b-form-select>
              </div>

              <div v-if="NewIncome.RecurringFrequency === 3">
                <label for="selected-day-of-month">Select Day of Month:</label>
                <b-form-input id="selected-day-of-month" v-model="NewIncome.selectedDayOfMonth" type="number" min="1"
                  max="31" placeholder="Day of Month"></b-form-input>
              </div>

              <div v-if="NewIncome.RecurringFrequency === 4">
                <label for="selected-day">Select Day:</label>
                <b-form-select id="selected-day" v-model="NewIncome.selectedDay" :options="dayOptions"></b-form-select>
              </div>

              <div v-if="NewIncome.RecurringFrequency === 5">
                <label for="selected-day-of-month">Select Day of Month:</label>
                <b-form-input id="selected-day-of-month" v-model="NewIncome.selectedDayOfMonth" type="number" min="1"
                  max="31" placeholder="Day of Month"></b-form-input>
                <label for="selected-occurrence">Select Month:</label>
              </div>

              <div v-if="NewIncome.RecurringFrequency === 6">
                <label for="selected-occurrence">Select Occurrence:</label>
                <b-form-select id="selected-occurrence" v-model="NewIncome.selectedOccurrence"
                  :options="Dayofweekoccurence"></b-form-select>
                <label for="selected-day">Select Day:</label>
                <b-form-select id="selected-day" v-model="NewIncome.selectedDay" :options="dayOptions"></b-form-select>
                <label for="selected-month">Select Month:</label>
                <b-form-select id="selected-month" v-model="NewIncome.selectedMonth"
                  :options="monthOptions"></b-form-select>
              </div>
            </div>

          </b-form-group>
          <b-button @click="getRecurringDates()">Get Dates</b-button>
          <b-button @click="submitReoccuringTransaction()">Submit</b-button>
        </b-col>
        <b-col cols="6">
          <table v-if="dates.length > 0">
            <thead>
              <tr>
                <th>Reoccuring Transaction's Future Dates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="date in dates" :key="date.index">
                <td>{{ date.format('YYYY-MM-DD') }}</td>
                <!-- <td>{{ date }}</td> -->
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { ModelSelect } from "vue-search-select";

export default {
  name: "NewIncome",
  computed: {
    AccountOptions() {
      if (this.accounts) {
        return [{
          value: null,
          text: "-- Select Account --",
        }].concat(this.accounts.map((i) => ({
          ...i,
          value: i.id,
          text: ` ${i.name}`,
        })));
      } else {
        return [{
          value: null,
          text: "None",
        }];
      }
    },
    // EnvelopeOptions() {
    //   if (this.envelopes) {
    //     var b = this.envelopes.map((i) => ({
    //       ...i,
    //       value: i.id,
    //       text: `${i.category.name} | ${i.name}`,
    //     }));
    //     b.unshift({
    //       value: null,
    //       text: "-- Select Envelope --",
    //     });
    //     return b;
    //   } else {
    //     return {
    //       value: null,
    //       text: "None",
    //     };
    //   }
    // },
  },
  components: {
    ModelSelect,
  },
  props: {
    EditIncome: {
      type: Object,
    },
  },
  data() {
    return {
      NewIncome: {
        split: false,
        name: null,
        amount: 0,
        description: null,
        accountId: null,
        envelopeId: null,
        startDate: moment()._d,
        showEndDate: false,
        endDate: null,
        selectedFrequencyTemaplate: null,
        RecurringInterval: 1,
        RecurringFrequency: null,
        selectedDay: null,
        selectedOccurrence: null,
        selectedDayOfMonth: null,
        selectedMonth: null,
        selectedDay: null,
      },
      RecurringTransactions: [],
      accounts: [],
      // Envelopes: [],
      frequencyTemplate: [
        { value: null, text: "-- Select a Template --" },
        { value: 0, text: "Daily" },
        { value: 1, text: "Bi-Weekly" },
        { value: 2, text: "Monthly (1st Friday)" },
        { value: 3, text: "Custom" },
      ],
      dates: [], // Initialize dates array that will be populated with the calculated dates
      dayOptions: [
        { value: null, text: "Select Day of Week" },
        { value: 0, text: "Sunday" },
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" },
      ],
      Dayofweekoccurence: [
        { value: null, text: "Select Occurrence" },
        { value: 1, text: "First" },
        { value: 2, text: "Second" },
        { value: 3, text: "Third" },
        { value: 4, text: "Fourth" },
        { value: 5, text: "Fifth" },
      ],
      monthOptions: [
        { value: null, text: "Select Month" },
        { value: 0, text: "January" },
        { value: 1, text: "February" },
        { value: 2, text: "March" },
        { value: 3, text: "April" },
        { value: 4, text: "May" },
        { value: 5, text: "June" },
        { value: 6, text: "July" },
        { value: 7, text: "August" },
        { value: 8, text: "September" },
        { value: 9, text: "October" },
        { value: 10, text: "November" },
        { value: 11, text: "December" },
      ],
      frequencyOptions: [
        { value: null, text: "Select Frequency" },
        { value: 0, text: "Daily" },
        { value: 1, text: "Weekly" },
        { value: 3, text: "Monthly (Day of Month)" },
        { value: 2, text: "Monthly (Day of Week)" },
        { value: 5, text: "Yearly (Day of Month)" },
        { value: 6, text: "Yearly (Day of Week)" },
      ],
    };
  },
  created() {
    this.refresh();
  },
  methods: {

    resetNewModal() {
      this.NewIncome = {
        split: false,
        name: null,
        amount: 0,
        description: null,
        accountId: null,
        envelopeId: null,
        startDate: moment()._d,
        showEndDate: false,
        endDate: null,
        selectedFrequencyTemaplate: null,
        RecurringInterval: 1,
        RecurringFrequency: null,
        selectedDay: null,
        selectedOccurrence: null,
        selectedDayOfMonth: null,
        selectedMonth: null,
        selectedDay: null,
      };
    },
    onShow() {

      if (this.EditIncome?.id) {
        console.log("this.EditIncome")
        console.log(this.EditIncome)
        this.NewIncome = {
          name: this.EditIncome.name,
          amount: this.EditIncome.amount,
          description: this.EditIncome.description,
          accountId: this.EditIncome.accountId,
          envelopeId: this.EditIncome.envelopeId,
          startDate: this.EditIncome.startDate,
          showEndDate: this.EditIncome.endDate ? true : false,
          endDate: this.EditIncome.endDate,
          selectedFrequencyTemaplate: this.EditIncome.selectedFrequencyTemaplate,
          RecurringInterval: this.EditIncome.RecurringInterval,
          RecurringFrequency: this.EditIncome.RecurringFrequency,
          selectedDay: this.EditIncome.selectedDay,
          selectedOccurrence: this.EditIncome.selectedOccurrence,
          selectedDayOfMonth: this.EditIncome.selectedDayOfMonth,
          selectedMonth: this.EditIncome.selectedMonth,
        };
      } else {
        this.resetNewModal();
      }
    },
    submitReoccuringTransaction() {
      axios.post("/api/incomes", {
        name: this.NewIncome.name,
        amount: this.NewIncome.amount.toFixed(2),
        description: this.NewIncome.description,
        accountId: this.NewIncome.accountId,
        envelopeId: this.NewIncome.envelopeId,
        startDate: this.NewIncome.startDate,
        endDate: this.NewIncome.endDate,
        RecurringFrequency: this.NewIncome.RecurringFrequency,
        RecurringInterval: this.NewIncome.RecurringInterval,
        selectedDay: this.NewIncome.selectedDay,
        selectedOccurrence: this.NewIncome.selectedOccurrence,
        selectedDayOfMonth: this.NewIncome.selectedDayOfMonth,
        selectedMonth: this.NewIncome.selectedMonth,
        selectedFrequencyTemaplate: this.NewIncome.selectedFrequencyTemaplate,
      }).then((res) => {
        setTimeout(() => {
          this.$parent.refresh();
          this.$bvModal.hide("NewIncome");
        }, 1000);

      })
    },
    refresh() {
      // axios.get("/api/Envelopes").then((res) => (this.envelopes = res.data));
      axios.get("/api/Accounts").then((res) => (this.accounts = res.data));
      // axios.get("/api/RecurringTransactions").then((res) => (this.RecurringTransactions = res.data));
    },
    getRecurringDates() {
      if (!this.NewIncome.startDate) {
        alert("Please select a start date.");
        return;
      }

      function getOccurrenceInMonth(date) {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        let occurrence = 0;
        for (let i = 1; i <= date.getDate(); i++) {
          const tempDate = new Date(date.getFullYear(), date.getMonth(), i);
          if (tempDate.getDay() === date.getDay()) {
            occurrence++;
          }
        }
        return occurrence;
      }
      function getOccurrenceInYear(date) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        let occurrence = 0;
        for (let i = 1; i <= date.getDate(); i++) {
          const tempDate = new Date(date.getFullYear(), date.getMonth(), i);
          if (tempDate.getDay() === date.getDay()) {
            occurrence++;
          }
        }
        return occurrence;
      }

      let endDate = null;
      if (this.NewIncome.endDate) {
        endDate = new Date(this.NewIncome.endDate);
      } else {
        endDate = new Date(this.NewIncome.startDate);
        endDate.setFullYear(endDate.getFullYear() + 1); // Set default end date to 1 years from start date
      }

      const dates = [];
      let startDate = new Date(this.NewIncome.startDate);
      let dayCount = 0;
      let weekCount = 0;


      // console.log(startDate)
      // console.log(endDate)

      while (startDate <= endDate) { // Calculate dates until the end date
        if ((this.NewIncome.RecurringFrequency === 0 && dayCount % this.NewIncome.RecurringInterval === 0) ||
          (this.NewIncome.RecurringFrequency === 1 && startDate.getDay() === parseInt(this.NewIncome.selectedDay) && weekCount % this.NewIncome.RecurringInterval === 0) ||
          (this.NewIncome.RecurringFrequency === 2 && startDate.getDay() === parseInt(this.NewIncome.selectedDay) && getOccurrenceInMonth(startDate) === parseInt(this.NewIncome.selectedOccurrence)) ||
          (this.NewIncome.RecurringFrequency === 3 && startDate.getDate() === parseInt(this.NewIncome.selectedDayOfMonth)) ||
          (this.NewIncome.RecurringFrequency === 5 && startDate.getDate() === parseInt(this.NewIncome.selectedDayOfMonth) && startDate.getMonth() === parseInt(this.NewIncome.selectedOccurrence)) ||
          (this.NewIncome.RecurringFrequency === 6 && startDate.getDay() === parseInt(this.NewIncome.selectedDay) && getOccurrenceInYear(startDate) === parseInt(this.NewIncome.selectedOccurrence) && startDate.getMonth() === parseInt(this.NewIncome.selectedMonth))) {
          dates.push(new Date(startDate));
        }
        startDate.setDate(startDate.getDate() + 1);
        dayCount++;
        if (startDate.getDay() === 0) { // If it's Sunday, increment the week count
          weekCount++;
        }
      }

      this.dates = dates;
    },

    updateSelectedDay() {
      const startDate = moment(this.NewIncome.startDate);
      this.NewIncome.selectedDay = startDate.day();
    },
  },
  watch: {
    'NewIncome.selectedFrequencyTemaplate'(newVal) {
      if (newVal === 0) {
        this.NewIncome.selectedDay = null;
        this.NewIncome.RecurringFrequency = 0;
        this.NewIncome.RecurringInterval = 1;
      }
      if (newVal === 1) {
        this.updateSelectedDay();
        this.NewIncome.RecurringFrequency = 1;
        this.NewIncome.RecurringInterval = 2;
      }
      if (newVal === 2) {
        this.NewIncome.selectedDay = 5;
        this.NewIncome.RecurringFrequency = 2;
        this.NewIncome.RecurringInterval = 0;
        this.NewIncome.selectedOccurrence = 1;
      }
    }
  },
  mounted() {
    this.updateSelectedDay();
  },
};
</script>
