import Vue from "vue";
import VueRouter from "vue-router";
import Envelopes from "./components/Envelopes.vue";
import Transactions from "./components/Transactions.vue";
import Budget from "./components/Budget.vue";
import Accounts from "./components/Accounts.vue";
import Income from "./components/Income.vue";
import EnvelopeDetail from "./components/EnvelopeDetail.vue";
import AccountDetail from "./components/AccountDetail.vue";
import Home from "./components/Home.vue";
import Settings from "./components/Settings.vue";
import RecurringTransactions from "./components/RecurringTransactions.vue";
import Authfinalize from "./components/Authfinalize.vue";
import Store from "./store"
import ReceiptUpload from "./components/ReceiptUpload.vue";
// import { authGuard, NotAuthGuard } from './auth/authGuard';

Vue.use(VueRouter);
let appRouter = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/authfinalize",
            name: "Authfinalize",
            component: Authfinalize,
        },
        {
            path: "/Envelopes",
            name: "Envelopes",
            component: Envelopes,

            meta: {
                title: "Envelopes",

                requiresAuth: true
            }
        },
        {
            path: "/Envelopes/:id",
            name: "EnvelopeDetail",
            component: EnvelopeDetail,

            meta: {
                title: "Envelopes",

                requiresAuth: true
            }
        },
        {
            path: "/Transactions",
            name: "Transactions",
            component: Transactions,

            meta: {
                title: "Transactions",

                requiresAuth: true
            }
        },
        {
            path: "/Budget",
            name: "Budget",
            component: Budget,

            meta: {
                title: "Budget",

                requiresAuth: true
            }
        },
        {
            path: "/Accounts",
            name: "Accounts",
            component: Accounts,

            meta: {
                title: "Accounts",

                requiresAuth: true
            }
        },
        {
            path: "/Accounts/:id",
            name: "AccountDetail",
            component: AccountDetail,

            meta: {
                title: "Accounts",

                requiresAuth: true
            }
        },
        {
            path: "/Income",
            name: "Income",
            component: Income,

            meta: {
                title: "Income",
                requiresAuth: true
            }
        },
        {
            path: "/home",
            name: "Home",
            component: Home,
            meta: {
                title: "Home",
                requiresGuest: true,
            }
        },
        {
            path: "/Settings",
            name: "Settings",
            component: Settings,

            meta: {
                title: "Settings",
                requiresAuth: true
            }
        },
        {
            path: "/RecurringTransactions",
            name: "RecurringTransactions",
            component: RecurringTransactions,

            meta: {
                title: "RecurringTransactions",
                requiresAuth: true
            }
        },
        {
            path: "/ReceiptUpload",
            name: "ReceiptUpload",
            component: ReceiptUpload,

            meta: {
                title: "ReceiptUpload",
                requiresAuth: true
            }
        },

        {
            path: "/*",
            redirect: '/Home'
        },
    ]
})

appRouter.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
    const auth0callback = to.matched.some(record => record.path == "/authfinalize");
    let routerAuthCheck = false;
    if (auth0callback) {
        // console.log("auth0callback")
        Store.dispatch('auth0HandleAuthentication');
        next(false);
    }
    if (localStorage.getItem('access_token') && localStorage.getItem('id_token') && localStorage.getItem('expires_at')) {
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        // console.log("expiresAt")
        // console.log(expiresAt)
        routerAuthCheck = new Date().getTime() < expiresAt;
    }
    Store.commit('setUserIsAuthenticated', routerAuthCheck);

    // console.log("routerAuthCheck")
    // console.log(routerAuthCheck)

    if (requiresAuth) {
        if (routerAuthCheck) {
            next();
        }
        else {
        //     function decodeJwt(token) {
        //         const base64Url = token.split('.')[1];
        //         const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        //         const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('binary').split('').map(function (c) {
        //             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        //         }).join(''));
            
        //         return JSON.parse(jsonPayload);
        //     };

        //     auth0.checkSession({}, function (err, authResult) {
        //         if (err) {
        //             console.log(err);
        //         } else {
        //             localStorage.setItem('access_token', authResult.accessToken);
        //             const decodedJwt = decodeJwt(authResult.accessToken);
        //             const exp = JSON.stringify(decodedJwt.exp * 1000);
        //             localStorage.setItem('expires_at', exp);
        //             routerAuthCheck = new Date().getTime() < expiresAt;
        //             if (routerAuthCheck) {
        //                 next();
        //             }
        //         }
        //     });

            Store.dispatch('logout');
            next({ name: "Home" });
        }
    }

    if (requiresGuest && routerAuthCheck) {
        next({ name: "Envelopes" });
    }
    next();
})


export default appRouter