<!-- Component to add transactions, move money between enveloeps with transfers, adding bank accounts, creating categroies, and adding envelopes   -->

<template>
  <div>
    <b-modal title="New Item" @show="onShow" @hidden="resetNewModal" id="NewEnvelope" hide-footer>
      <!-- <template #default="{ ok, cancel, hide }"> -->
      <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Type"
        label-for="input-type">
        <b-select v-model="New.type" :options="NewSelect" id="input-type">
        </b-select>
      </b-form-group>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="New.type == 'Envelope'"> -->
      <b-form v-if="New.type == 'Category'" @submit.prevent="SubmitNew">
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Name"
          label-for="input-name">
          <b-input required v-model="New.name" id="input-name"></b-input>
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Description"
          label-for="input-description">
          <b-input v-model="New.description" id="input-description"></b-input>
        </b-form-group>


        <b-col md="5" class="ml-md-auto">
          <b-checkbox v-model="KeepOpenAfterSubmit" name="KeepOpenAfterSubmit">
            Keep Open
          </b-checkbox>
          <b-button variant="secondary" @click="hideModal">
            Cancel
          </b-button>
          <b-button variant="primary" type="submit">
            OK
          </b-button>

        </b-col>



      </b-form>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="New.type == 'Envelope'"> -->
      <b-form v-if="New.type == 'Envelope'" @submit.prevent="SubmitNew">
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Category"
          label-for="input-categoryId">
          <model-select required id="input-categoryId" :options="CategoryOptions" v-model="New.categoryId">
          </model-select>
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Name"
          label-for="input-name">
          <b-input required v-model="New.name" id="input-name"></b-input>
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Description"
          label-for="input-description">
          <b-input v-model="New.description" id="input-description"></b-input>
        </b-form-group>
        <b-col md="5" class="ml-md-auto">
          <b-checkbox v-model="KeepOpenAfterSubmit" name="KeepOpenAfterSubmit">
            Keep Open
          </b-checkbox>
          <b-button variant="secondary" @click="hideModal">
            Cancel
          </b-button>
          <b-button variant="primary" type="submit">
            OK
          </b-button>

        </b-col>
      </b-form>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="New.type == 'Transaction'"> -->
      <b-form v-if="New.type == 'Transaction'" @submit.prevent="SubmitNew">
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Date"
          label-for="input-date">
          <b-input required id="input-date" type="date" v-model="New.date"> </b-input>
        </b-form-group>


        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Check?"
          label-for="input-envelope">
          <b-input-group>
            <b-form-checkbox v-model="CheckQ">{{
              CheckQ ? "Yes" : "No"
            }}</b-form-checkbox>
            <b-form-group v-if="CheckQ" label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7"
              label="Check #" label-for="input-checknumber">
              <b-input required type="number" v-model="New.checknumber" id="input-checknumber"></b-input>
            </b-form-group>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Account"
          label-for="input-Account">
          <model-select id="input-Account" :options="AccountOptions" v-model="New.accountId">
          </model-select>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Payee"
          label-for="input-payee">
          <b-input required v-model="New.payee" id="input-payee"></b-input>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Description"
          label-for="input-description">
          <b-input v-model="New.description" id="input-description"></b-input>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Amount"
          label-for="input-Amount">
          <!-- <b-input type="number" v-model="New.amount" id="input-Amount"></b-input> -->
          <money required v-bind:class="New.amount < 0 ? 'error' : 'success'" v-model="New.amount" class="form-control">
          </money>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Target Envelope"
          label-for="input-envelope">
          <model-select id="input-envelope" :options="EnvelopeOptions" v-model="New.envelope">
          </model-select>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Target Balance"
          label-for="input-TargetBalance">
          <div v-if="New.envelope && New.amount != 0" style="display: flex">
            <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(TargetBalance) }}
            </div>
            <div>==></div>
            <div v-bind:class="TargetFutureBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(TargetFutureBalance) }}
            </div>
          </div>
          <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'" v-if="New.envelope && New.amount == 0">
            {{ formatcurency(TargetBalance) }}
          </div>
          <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'" v-else>

          </div>
        </b-form-group>
        <b-col md="5" class="ml-md-auto">
          <b-checkbox v-model="KeepOpenAfterSubmit" name="KeepOpenAfterSubmit">
            Keep Open
          </b-checkbox>
          <b-button variant="secondary" @click="hideModal">
            Cancel
          </b-button>
          <b-button variant="primary" type="submit">
            OK
          </b-button>

        </b-col>
      </b-form>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="New.type == 'Transfer'"> -->
      <b-form v-if="New.type == 'Transfer'" @submit.prevent="SubmitNew">
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Amount"
          label-for="input-Amount">
          <!-- <b-input type="number" v-model="New.amount" id="input-Amount"></b-input> -->
          <money required v-bind:class="New.amount < 0 ? 'error' : 'success'" v-model="New.amount" class="form-control">
          </money>
        </b-form-group>


        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Source Envelope"
          label-for="input-Sourceenvelope">
          <model-select id="input-Sourceenvelope" :options="EnvelopeOptions" v-model="New.Sourceenvelope">
          </model-select>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Source Balance"
          label-for="input-SourceBalance">


          <!-- <div v-bind:class="SourceBalance < 0 ? 'error' : 'success'">
            {{ formatcurency(SourceBalance) }}
          </div> -->

          <div v-if="SourceBalance && New.amount != 0" style="display: flex">
            <div v-bind:class="SourceBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(SourceBalance) }}
            </div>
            <div> ==> </div>
            <div v-bind:class="SourceFutureBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(SourceFutureBalance) }}
            </div>
          </div>
          <div v-bind:class="SourceBalance < 0 ? 'error' : 'success'" v-if="New.SourceBalance && New.amount == 0">
            {{ formatcurency(SourceBalance) }}
          </div>
          <div v-bind:class="SourceBalance < 0 ? 'error' : 'success'" v-else>
          </div>

        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Target Envelope"
          label-for="input-envelope">
          <model-select id="input-envelope" :options="EnvelopeOptions" v-model="New.envelope">
          </model-select>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Target Balance"
          label-for="input-TargetBalance">
          <div v-if="New.envelope && New.amount != 0" style="display: flex">
            <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(TargetBalance) }}
            </div>
            <div> ==> </div>
            <div v-bind:class="TargetFutureBalance < 0 ? 'error' : 'success'">
              {{ formatcurency(TargetFutureBalance) }}
            </div>
          </div>
          <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'" v-if="New.envelope && New.amount == 0">
            {{ formatcurency(TargetBalance) }}
          </div>
          <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'" v-else>
          </div>
        </b-form-group>

        <b-col md="5" class="ml-md-auto">
          <b-checkbox v-model="KeepOpenAfterSubmit" name="KeepOpenAfterSubmit">
            Keep Open
          </b-checkbox>
          <b-button variant="secondary" @click="hideModal">
            Cancel
          </b-button>
          <b-button variant="primary" type="submit">
            OK
          </b-button>

        </b-col>
      </b-form>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="New.type == 'Account'"> -->
      <b-form v-if="New.type == 'Account'" @submit.prevent="SubmitNew">

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Name"
          label-for="input-name">
          <b-input v-model="New.name" id="input-name"></b-input>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Description"
          label-for="input-description">
          <b-input v-model="New.description" id="input-description"></b-input>
        </b-form-group>

        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Starting Balance"
          label-for="input-startingbalance">
          <!-- <b-input
          type="number"
          v-model="New.startingbalance"
          id="input-startingbalance"
        ></b-input> -->
          <money v-bind:class="New.startingbalance < 0 ? 'error' : 'success'" class="form-control"
            v-model="New.startingbalance"></money>
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Target Envelope"
          label-for="input-envelope">
          <model-select id="input-envelope" :options="EnvelopeOptions" v-model="New.envelope">
          </model-select>
        </b-form-group>

        <b-col md="5" class="ml-md-auto">
          <b-checkbox v-model="KeepOpenAfterSubmit" name="KeepOpenAfterSubmit">
            Keep Open
          </b-checkbox>
          <b-button variant="secondary" @click="hideModal">
            Cancel
          </b-button>
          <b-button variant="primary" type="submit">
            OK
          </b-button>

        </b-col>



      </b-form>


      <!-- Emulate built in modal footer ok and cancel button actions -->


      <!-- </template> -->

    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ModelSelect } from "vue-search-select";

export default {
  name: "NewItem",
  props: {
    newtype: {
      type: String,
    },
  },
  components: { ModelSelect },
  methods: {
    refresh() {
      

      axios
        .get("/api/Envelopes/balances")
        .then((res) => (this.Envelopes = res.data));
      axios.get("/api/Categories").then((res) => {
        this.Categories = res.data
        if (this.Categories.length < 1) {
          if (this.New.type == "Envelope") {
            this.New.type = "Category";
          }
        }
      });
      axios.get("/api/Accounts").then((res) => (this.Accounts = res.data));

      if (this.newtype) {

        this.New.type = this.newtype;
      }

    },
    resetNewModal() {
      // console.log("reset");
      this.New = {
        accountId: null,
        Sourceenvelope: null,
        category: null,
        categoryId: null,
        name: "",
        description: "",
        envelope: null,
        type: this.newtype,
        date: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      };
  this.refresh();
    },
    // CheckQuestion() {
    //   console.log("show");
    //   console.log(this.newtype);
    //   if (this.newtype) {
    //     this.New.type = this.newtype;
    //   }
    // },
    onShow() {
      // console.log("show");
      // console.log(this.newtype);
      this.refresh();

     

    },
    hideModal() {
      this.$bvModal.hide("NewEnvelope");
    },
    SubmitNew() {
      var New = this.New;
      // console.log(this.New.type)
      if (New.type == "Envelope") {
        if (!this.New.categoryId) {
          alert('Category is required');
          return;
        }
        axios.post("/api/envelopes", New);
      }
      if (New.type == "Category") {
        axios.post("/api/categories", New);
      }
      if (New.type == "Account") {
        axios.post("/api/accounts", New);
      }
      if (New.type == "Transaction") {
        New.envelopeId = New.envelope;
        New.accountId = New.accountId;
        axios.post("/api/transactions", New);
      }
      if (New.type == "Transfer") {
        if (Number(New.amount) <= 0) {
          alert('Amount is required and must be greater than 0');
          return;
        }
        if (New.Sourceenvelope != New.envelope) {
          // console.log(New);
          var request = {
            Sourceenvelope: { id: null },
            envelope: { id: null },
          };
          request.date = New.date;
          request.amount = New.amount;
          request.Sourceenvelope.id = New.Sourceenvelope;
          request.envelope.id = New.envelope;
          axios.post("/api/transfer", request);
        }
      }
      // console.log(this.New);
      if (!this.KeepOpenAfterSubmit) {
        this.hideModal();
      } else {

        this.resetNewModal();
      }
      setTimeout(
        function () {
          this.$parent.refresh();
          this.refresh();
        }.bind(this),
        1000
      );
    },
  },
  data() {
    return {
      KeepOpenAfterSubmit: false,
      New: {
        accountId: null,
        Sourceenvelope: null,
        envelope: null,
        type: "",
        categoryId: null,
        name: "",
        description: "",
        date: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      },

      Categories: [],
      Envelopes: [],
      Accounts: [],
      CheckQ: false,
    };
  },
  created() { },
  watch: {
    // newtype: {
    //   handler: function (val) {


    //   },
    //   deep: true,
    // },
  },
  computed: {
    NewSelect() {
      if (this.Categories.length > 0) {

        return [
          { text: "Envelope", value: "Envelope", disabled: false },
          { text: "Category", value: "Category", disabled: false },
          { text: "Account", value: "Account", disabled: false },
          { text: "Transaction", value: "Transaction", disabled: false },
          { text: "Transfer", value: "Transfer", disabled: false }];
      }
      else {
        return [
          { text: "Envelope", value: "Envelope", disabled: true },
          { text: "Category", value: "Category", disabled: false },
          { text: "Account", value: "Account", disabled: false },
          { text: "Transaction", value: "Transaction", disabled: false },
          { text: "Transfer", value: "Transfer", disabled: false }];
      }
    },
    SourceFutureBalance() {
      if (this.New.Sourceenvelope) {
        return Number(this.Envelopes.filter((i) => i.id == this.New.Sourceenvelope)[0]
          .balance) - Number(this.New.amount);
      }
      return 0;
    },
    TargetFutureBalance() {
      if (this.New.envelope) {
        return Number(this.Envelopes.filter((i) => i.id == this.New.envelope)[0]
          .balance) + Number(this.New.amount);
      }
      return 0;
    },
    TargetBalance() {
      if (this.New.envelope) {
        return this.Envelopes.filter((i) => i.id == this.New.envelope)[0]
          .balance;
      }
      return 0;
    },
    SourceBalance() {
      // console.log("change Source");
      if (this.New.Sourceenvelope) {
        // console.log(this.New.Sourceenvelope);
        return this.Envelopes.filter((i) => i.id == this.New.Sourceenvelope)[0]
          .balance;
      }
      return 0;
    },
    AccountOptions() {
      if (this.Accounts) {
        let a = this.Accounts.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.name}`,
        }));
        a.unshift({ value: null, text: "-- Select Account --" });
        return a;
      } else {
        return { value: null, text: "None" };
      }
    },
    EnvelopeOptions() {
      if (this.Envelopes) {
        let a = this.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        a.unshift({ value: null, text: "-- Select Envelope --" });
        return a;
      } else {
        return { value: null, text: "None" };
      }
    },
    CategoryOptions() {
      if (this.Categories) {
        let a = this.Categories.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.name}`,
        }));
        a.unshift({ value: null, text: "-- Select Category --" });
        return a
      } else {
        return { value: null, text: "None" };
      }
    },
  },
};
</script>
<style scoped></style>
