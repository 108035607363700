import Vuex from "vuex";
import Vue from "vue";
import authActions from "./auth/actions";
import authGetters from "./auth/getters";
import authMutations from "./auth/mutations";
import auth0 from "auth0-js";
import axios from 'axios';

Vue.use(Vuex);

const state = () => ({
  userIsAuthorized: false,
  userAccountStatus: {
    enabled: null,
    firstLoginDate: null,
    trialStarted: null,
    trialStartDate: null,
    TrialEndDate: null,
    subscriptionId: null,
  },
  auth0: new auth0.WebAuth({
    domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
    redirectUri: process.env.VUE_APP_DOMAINURL_REDIRECT,
    responseType: process.env.VUE_APP_AUTH0_CONFIG_RESPONSETYPE,
    scope: process.env.VUE_APP_AUTH0_CONFIG_SCOPE,
    audience: process.env.VUE_APP_AUTH0_CONFIG_AUDIENCE,
  }),
});

const actions = {
  ...authActions, // spread the actions from the auth folder

  checkUserStatus: ({ commit }) => {
    axios.get(`/api/user/status`)
      .then(response => {
        commit('SET_USER_STATUS', response.data);
      });
  },
};

const getters = {
  ...authGetters,
  getUserStatus: (state) => { return state.userAccountStatus; },
};

const mutations = {
  ...authMutations,

  SET_USER_STATUS: (state, status) => {
    // console.log(status)
    state.userAccountStatus = status;
  },
};

export default new Vuex.Store({
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
});
