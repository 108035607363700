import router from '../../../router'
import { jwtDecode } from 'jwt-decode';


const auth0HandleAuthentication = (context) => {



  context.state.auth0.parseHash((err, authResult) => {

    function decodeJwt(token) {
      const decoded = jwtDecode(token);
      console.log(decoded);
      return decoded;
    };


    if (authResult && authResult.accessToken && authResult.idToken) {
      // let expiresAt = JSON.stringify(
      //   (process.env.VUE_APP_EXPIRY * 198) + new Date().getTime()
      // );
      const decodedJwt = decodeJwt(authResult.accessToken);
      // console.log(decodedJwt)
      const exp = JSON.stringify(decodedJwt.exp * 1000);
      // save the tokens locally
      localStorage.setItem("access_token", authResult.accessToken);
      localStorage.setItem("id_token", authResult.idToken);
      localStorage.setItem("expires_at", exp);

      // Dispatch the checkUserStatus action
      context.dispatch('checkUserStatus');

      router.push({ name: "Envelopes" })
    }
    else if (err) {
      console.log("There's is an issue. Try again.", err)
      router.replace({ name: "home" })
    }
  })
};

export default auth0HandleAuthentication;
