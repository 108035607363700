// import authConfig from '../../../../auth_config.json';
import router from '../../../router'
import axios from 'axios';
// import router from '../../../router';


// const { AUTH0_CONFIG_DOMAINURL } = authConfig;
// const { DOMAINURL } = authConfig;
// const { AUTH0_CONFIG_CLIENTID } = authConfig;

const logout = () => {

      console.log("logout");
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");

      window.location.href = `https://${process.env.VUE_APP_AUTH0_CONFIG_DOMAIN}/v2/logout?client_id=${process.env.VUE_APP_AUTH0_CONFIG_CLIENTID}&returnTo=${encodeURIComponent(window.location.origin)}`;
      
      // router.push({ name: "Home" });
    };


    // router.push({ name: "Home" })


export default logout;
