<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <b-jumbotron class="vertical-center">
      <b-col align="center">
        <b-card>
          <b-button size="lg" v-if="!loggedin" @click="login">Login</b-button>
        </b-card>
      </b-col>
    </b-jumbotron>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  title: "Home",
  name: "Home",
  created() {
    // this.$store.commit("clearToken");
  },
  components: {

  },
  computed: {
    ...mapGetters(['userIsAuthorized']),
    loggedin() {
      if (this.userIsAuthorized || this.devchange) {
        // return this.$auth.isAuthenticated;
        return true;
      }
    },
  },
  methods: {
    // Log the user in
    login() {
      this.$store.dispatch('login')
    },
    // Log the user out
    logout() {
      this.$store.dispatch('logout')
    },
  },
};
</script>

<style scoped>
#d {
  height: 100%;
}

/* .largertext a {
  font-size: large;
}
.largertext span {
  font-size: large;
}
.largertext div {
  font-size: large;
} */
.vertical-center {
  min-height: 60%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 60vh;
  /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
</style>
