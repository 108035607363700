<template>
  <div>
    <b-modal @hide="Cancel" @show="onShow" @ok="SubmitSplit()"
      :title="`Split Transaction Total: $${(Number(EditTransactionData?.amount) || 0).toFixed(2)} Remaining: $${(Number(Remaining) || 0).toFixed(2)}`"
      id="SplitTransaction" :ok-disabled="SplitTotalCalc != EditTransactionData?.amount || !ListChanged">
      <b-table small striped hover outlined bordered responsive show-empty :items="SplitList" :fields="Splitfields"
        class="tab-res">
        <template v-slot:cell(AmountEdit)="data">
          <money v-model="data.item.amount" class="form-control"></money>
        </template>
        <template v-slot:cell(EnvEdit)="data">
          <model-select :options="EnvelopeOptionsFiltered" v-model="data.item.envelopeId">
          </model-select>
        </template>
        <template v-slot:cell(Actions)="data">
          <b-button variant="danger" size="sm" @click="Delete(data.index)">X</b-button>
        </template>
        <template slot="top-row">
          <td class="bottom-row-style">
            <model-select :options="EnvelopeOptionsFiltered" v-model="newsplit.envelopeId">
            </model-select>
          </td>
          <td class="bottom-row-style">
            <money v-model="newsplit.amount" class="form-control" @keydown.enter="addSplit"></money>
            <!-- <currency-input v-model="newsplit.amount"/> -->
            <!-- <b-input v-model="newsplit.amount" class="form-control"></b-input> -->
          </td>
          <td class="bottom-row-style">
            <b-button @click="addSplit()">Add</b-button>
          </td>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "SplitEditing",
  props: {
    EditTransactionData: {
      type: Object,
    },
  },
  components: { ModelSelect },
  methods: {
    Delete(i) {
      console.log(i);
      this.SplitList.splice(i, 1);
    },
    Cancel() {
      this.resetNewModal();
      if (this.importedtransactionsplits) {
        this.EditTransactionData.split = false;
      }

    },
    resetNewModal() {
      this.newsplit = {
        envelopeId: null,
        amount: 0,
      };
      this.SplitList = [];
      this.importedtransactionsplits = false;


    },
    updateAmount(event) {
      this.newsplit.amount = event.target.value;
    },
    addSplit() {
      console.log('addSplit method called');
      this.SplitList.push({ ...this.newsplit });
      this.newsplit = {
        envelopeId: null,
        amount: 0,
      };
      console.log(this.newsplit);
    },
    onShow() {
      this.resetNewModal();
      setTimeout(
        function () {
          if (this.EditTransactionData?.transactionsplits) {
            this.importedtransactionsplits = true
            console.log(this.EditTransactionData?.transactionsplits);
            this.SplitList = this.EditTransactionData?.transactionsplits.map(
              (i) => {
                return {
                  envelopeId: i.envelopeId,
                  amount: Number(i.amount),
                  // amount: i.amount,
                };
              }
            );
            this.SplitListOrg = JSON.stringify(this.SplitList);
          }
        }.bind(this),
        800
      );
    },
    SubmitSplit() {
      var i = this.EditTransactionData;
      if (this.SplitList.length > 1) {
        i.SplitList = this.SplitList;
      }
      if (this.SplitList.length == 1) {
        i.split = false;
        i.envelopeId = this.SplitList[0].envelopeId;
      }
      this.$parent.saveTransaction(i);
    },
  },
  data() {
    return {
      importedtransactionsplits: false,
      newsplit: {
        envelopeId: null,
        amount: 0,
      },
      SplitList: [],
      SplitListOrg: [],
      Splitfields: [
        {
          key: "EnvEdit",
          label: "Envelope",
          variant: "none",
          class: "col-2",
        },
        {
          key: "AmountEdit",
          label: "Amount",
          variant: "none",
          class: "col-1",
        },
        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ],
      // NewSelect: ["Envelope", "Category", "Account", "Transaction", "Transfer"],
      // Categories: [],
      // Envelopes: [],
      // Accounts: [],
      // CheckQ: false,
    };
  },
  created() {
    // axios.get("/api/Envelopes").then((res) => (this.Envelopes = res.data));
    // axios.get("/api/Categories").then((res) => (this.Categories = res.data));
    // axios.get("/api/Accounts").then((res) => (this.Accounts = res.data));
  },
  computed: {
    ListChanged() {
      if (JSON.stringify(this.SplitList) != this.SplitListOrg) {
        console.log(this.SplitListOrg);
        console.log(JSON.stringify(this.SplitList));
        return true;
      }
      return false;
    },
    Remaining() {
      return Number(this.EditTransactionData?.amount) - Number(this.SplitTotalCalc)
    },
    SplitTotalCalc() {
      let sum = 0;
      this.SplitList?.forEach((element) => {
        sum += Number(element.amount);
      });
      return Number(sum.toFixed(2));
    },
    EnvelopeOptionsFiltered() {
      if (this.$parent.Envelopes) {
        var b = this.$parent.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        // b = b.filter(e => {
        //   e.id !=
        // })
        b.unshift({ value: null, text: "-- Select Envelope --" });
        return b;
      } else {
        return { value: null, text: "None" };
      }
    },
  },
};
</script>
<style scoped>
.tab-res {
  overflow-x: unset;
}

@media (min-width: 320px) and (max-width: 767px) {
  .tab-res {
    overflow-x: auto;
    min-height: 200px;
  }
}
</style>
