<template>
  <div class="item my-5">
    <b-row class="mb-4">
      <b-col cols="12" md="6">
        <h2 v-if="CurrentEnvelopeName?.name">{{ CurrentEnvelopeName.name }}</h2>
      </b-col>
      <b-col cols="12" md="6" class="d-flex">
        <b-form-input
          class="p-4"
          v-model="filter"
          placeholder="Search..."
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          <b-button class="ml-2" v-b-modal.NewEnvelope> + </b-button>
          <b-button v-b-toggle.collapse-1-inner size="sm">Show Graph</b-button>
        </b-input-group-append>
      </b-col>
      <b-col cols="12" md="12">
        <b-collapse id="collapse-1-inner" class="mt-2">
          <div>
            <apexchart
              width="100%"
              height="400"
              type="bar"
              :options="chartOptions"
              :series="chartOptions.series"
            ></apexchart>
          </div>
        </b-collapse>
      </b-col>
    </b-row>

    <b-table
      striped
      hover
      outlined
      bordered
      responsive
      show-empty
      :items="transactions"
      :fields="transactionsfields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
    >
      <template v-slot:cell(EnvelopeEdit)="data">
        <div
          v-if="
            data.item.edit == true &&
            data.item.envelopeId &&
            !data.item.transaction?.split
          "
        >
          <model-select
            id="input-envelope"
            :options="EnvelopeOptions"
            v-model="data.item.envelopeId"
          ></model-select>
        </div>
        <div v-else-if="data.item.edit == true && data.item.toenvelopeId">
          <model-select
            id="input-envelope"
            :options="EnvelopeOptions"
            v-model="data.item.toenvelopeId"
          ></model-select>
        </div>
        <div v-else>
          {{ data.item.envelope?.name || data.item.toenvelope?.name }}
        </div>
      </template>
      <template v-slot:cell(PayeeEdit)="data">
        <div
          v-if="
            !data.item.transaction?.split &&
            data.item.edit == true &&
            data.item.type != 'Transfer' &&
            data.item.type != 'Starting Balance'
          "
        >
          <b-form-input v-model="data.item.payee" />
        </div>
        <div v-else>
          {{ data.item.payee }}
        </div>
      </template>
      <template v-slot:cell(AmountEdit)="data">
        <div v-if="data.item.edit == true && !data.item.transaction?.split">
          <b-form-input v-model="data.item.amount" />
        </div>
        <div v-else v-bind:class="data.item.amount < 0 ? 'error' : 'success'">
          {{ formatcurency(data.item.amount) }}
        </div>
      </template>
      <template v-slot:cell(CheckEdit)="data">
        <div
          v-if="
            !data.item.transaction?.split &&
            data.item.edit == true &&
            data.item.type != 'Transfer'
          "
        >
          <b-form-input v-model="data.item.checknumber" />
        </div>
        <div v-else>
          {{ data.item.checknumber }}
        </div>
      </template>
      <template v-slot:cell(DateEdit)="data">
        <div v-if="!data.item.transaction?.split && data.item.edit == true">
          <b-form-datepicker
            :value-as-date="true"
            type="date"
            v-model="data.item.date"
          />
        </div>
        <div v-else>
          {{ new Date(data.item.date).format("MM-DD-YYYY") }}
        </div>
      </template>
      <template v-slot:cell(Actions)="data">
        <b-button-group>
          <b-button
            v-if="!data.item.transaction?.split"
            size="sm"
            @click="
              saveTransaction(data.item);
              data.item.edit = !data.item.edit;
            "
            class="mr-1"
          >
            <b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button>
          <!-- <b-button v-if="!data.item.transaction?.split" size="sm" @click="
                                                                                      if (data.item.edit === true) {
            saveTransaction(data.item);
          } else {
            data.item.edit = !data.item.edit;
          }  " class="mr-1"><b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button> -->
          <b-button
            v-if="data.item.edit"
            size="sm"
            @click="Delete(data.item)"
            class="mr-1"
            variant="danger"
          >
            <b-icon-trash />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <NewItem newtype="Envelope" />
    <SplitEditing :EditTransactionData="TransactionSplitEdit" />
  </div>
</template>

<script>
import axios from "axios";
import NewItem from "./NewItem.vue";
import { ModelSelect } from "vue-search-select";
import SplitEditing from "./SplitEditing.vue";

export default {
  name: "EnvelopeDetail",
  components: { NewItem, SplitEditing, ModelSelect },
  methods: {
    async saveTransaction(i) {
      if (i.edit) {
        //console.log(i);
        if (i.type == "Transaction" || "Starting Balance") {
          // console.log("transaction");
          axios.post("/api/transactions", i).then((i) => {
            setTimeout(
              function () {
                //console.log("test");
                this.refresh();
              }.bind(this),
              1000
            );
          });
        }
        if (i.type == "Transaction Splits") {
          //console.log("Transaction Splits");
          var d = {};
          d.id = i.id;
          d.amount = i.amount;
          d.transactionId = i.transactionId;
          d.envelopeId = i.envelopeId;

          axios.post("/api/transactionsplits", [d]).then((i) => {
            setTimeout(
              function () {
                //console.log("test");
                this.refresh();
              }.bind(this),
              1000
            );
          });
        }
        if (i.type == "Transfer") {
          axios
            .post("/api/transfer", i)
            .then((i) => {
              setTimeout(
                function () {
                  //console.log("test");
                  this.refresh();
                }.bind(this),
                1000
              );
            });
        }
        // this.refresh();
      }
    },
    GetProfileDetails() {
      if (this.FundEnvelopesData.profile) {
        var results = [];
        axios
          .get(`/api/IncomeProfile${this.FundEnvelopesData.profile}`)
          .then((res) => {
            this.ProfileDetails = res.data.filter((i) => {
              return i.amount > 0;
            });
            //console.log(res.data);
            return results;
          });
      }
    },
    FundEnvelopes() {
      //console.log(this.FundEnvelopesData);
    },
    Delete(i) {
      //console.log(i);
      //console.log(i.type);
      var index = this.transactions.findIndex((t) => {
        return t.id == i.id && t.type == i.type;
      });
      //console.log("index");
      //console.log(index);
      if (i.type == "Transaction" || i.type == "Starting Balance") {
        //console.log("transaction");

        if (
          confirm(`Delete transaction? ${i.date} - ${i.payee} - $${i.amount}`)
        ) {
          //console.log(index);
          axios.delete("/api/transactions", { data: i }).then(
            this.transactions.splice(index, 1).then((i) => {
              setTimeout(
                function () {
                  //console.log("test");
                  this.refresh();
                }.bind(this),
                1000
              );
            })
          );
        }
      }
      if (i.type == "Transfer") {
        //console.log("Transfer");

        if (confirm(`Delete transfer? ${i.date} - ${i.payee} - $${i.amount}`)) {
          //console.log(index);
          axios.delete("/api/transfer", { data: i }).then(
            this.transactions.splice(index, 1).then((i) => {
              setTimeout(
                function () {
                  //console.log("test");
                  this.refresh();
                }.bind(this),
                1000
              );
            })
          );
        }
      }

      // this.refresh();
    },
    refresh() {
      axios
        .get(
          `/api/envelopeMonthTotals/${this.$route.params.id}/6/${new Date()}`
        )
        .then((res) => (this.MonthlyTotals = res.data));
      axios.get("/api/envelopes/" + this.$route.params.id).then((res) => {
        //console.log(res.data);
        var transactions = res.data;
        var data = [];
        // console.log(this.transactions);
        // console.log(data);
        // var data = this.transactions.forEach((t) => {
        //   console.log(t);
        // });

        if (transactions.transactions?.length > 0) {
          //console.log("transactions");

          transactions.transactions.forEach((i) => {
            i.type = "Transaction";
            if (i.payee.includes("Starting balance")) {
              i.type = "Starting Balance";
            }
            i.edit = false;

            data.push(i);
            //console.log(i);
          });
        }
        if (transactions.fromtransfers?.length > 0) {
          //console.log("fromtransfers");
          transactions.fromtransfers.forEach((i) => {
            i.type = "Transfer";
            i.edit = false;
            i.payee = `Transfer from ${i.fromenvelope.name}`;
            i.amount = -Math.abs(i.amount);
            data.push(i);
            //console.log(i);
          });
        }
        if (transactions.totransfers?.length > 0) {
          //console.log("totransfers");
          transactions.totransfers.forEach((i) => {
            i.type = "Transfer";
            i.edit = false;
            i.payee = `Transfer from ${i.fromenvelope.name}`;
            i.amount = Math.abs(i.amount);
            data.push(i);
            //console.log(i);
          });
        }
        if (transactions.transactionsplits?.length > 0) {
          //console.log("transactionsplits");
          transactions.transactionsplits.forEach((i) => {
            //console.log(i);
            i.type = "Transaction Split";
            i.edit = false;
            i.payee = i.transaction?.payee;
            i.date = i.transaction?.date;
            i.checknumber = i.transaction?.checknumber;
            i.account = i.transaction?.account;
            data.push(i);
          });
        }
        this.transactions = data;
      });
      axios.get("/api/Envelopes").then((res) => (this.Envelopes = res.data));
    },
  },
  data() {
    return {
      FundEnvelopesData: {
        profile: 1,
        FromEnvelope: null,
      },
      MonthlyTotals: [],
      TransactionSplitEdit: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortBy: "date",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      NewSelect: ["Envelope", "Category"],
      Categories: [],
      Envelopes: [],
      transactions: [],
      ProfileDetails: [],
      transactionsfields: [
        {
          key: "DateEdit",
          label: "Date",
          class: "col-2",
          variant: "none",
          sortDirection: "desc",
        },
        {
          key: "type",
          label: "Type",
          class: "col-2",
          variant: "none",
          sortDirection: "desc",
        },
        {
          key: "account.name",
          label: "Account",
          variant: "none",
          class: "col-2",
        },
        {
          key: "PayeeEdit",
          label: "Payee",
          variant: "none",
          class: "col-2",
        },
        {
          key: "CheckEdit",
          label: "Check #",
          variant: "none",
          class: "col-1",
        },

        {
          key: "AmountEdit",
          label: "Amount",
          variant: "none",
          class: "col-1",
        },
        {
          key: "EnvelopeEdit",
          label: "Envelope",
          variant: "none",
          class: "col-2",
        },
        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: "Monthly Overview",
          stacked: true, // This should be set to true for stacking
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '10%', // Adjust this value to make bars slimmer
            colors: {
              ranges: [
                {
                  from: -1000000000,
                  to: 0,
                  color: '#FF4560' // Red for negative values (expenses)
                },
                {
                  from: 0,
                  to: 1000000000,
                  color: '#00E396' // Green for positive values (income)
                }
              ]
            }
          }
        },
        xaxis: {
          categories: this.MonthlyTotals.map((i) => {
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const monthName = monthNames[i.month - 1];
            return `${monthName} ${i.year}`;
          }),
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0); // Format the label to be an integer
            }
          },
          tickAmount: 10, // Adjust the tick amount to suit the range of your data
        },
        series: [
          {
            name: "Expenses",
            color: '#FF4560',
            data: this.MonthlyTotals.map((i) => {
              return i.expenses;
            }),
          },
          {
            name: "Income",
            color: '#00E396',
            data: this.MonthlyTotals.map((i) => {
              return i.income;
            }),
          },
        ],
      };
    },
    CurrentEnvelopeName() {
      if (this.Envelopes) {
        return this.Envelopes.filter((e) => {
          return e.id == this.$route.params.id;
        })[0];
      }
    },
    EnvelopeOptions() {
      if (this.Envelopes) {
        return this.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
      } else {
        return { value: null, text: "None" };
      }
    },
  },
};
</script>
<style scoped></style>
